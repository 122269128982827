import React from 'react'

import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import Home from "../Home/Home";

import UnexpectedError from "../Router/Errors/UnexpectedError"
import E404 from "./Errors/E404";
type Props = {}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
    errorElement: <UnexpectedError /> 
  },

  {
    path: "/*",
    element: <Navigate to="/" />,
    errorElement: <UnexpectedError /> ,

  },
]);

function Router({}: Props) {
  return (
    <RouterProvider router={router} />

  )
}

export default Router
