import React, { Component } from 'react'
import DSCodings from "../images/DSCodings.png"
import '../App.css'
import '../css/Styles.css'
import Cursor from './Cursor'
import { useMediaQuery } from 'react-responsive';
type Props = { refs: any, cbref: any }

function About({ refs, cbref }: Props) {
    const isSmallScreen = useMediaQuery({ maxWidth: 800 });
    const isLargeScreen = useMediaQuery({ minWidth: 801 });

    return (
        <div ref={refs} style={{ width: "100vw", height: "100vh", backgroundColor: "green", minHeight: "360px" }} >
            <div ref={cbref} style={{ width: "100vw", height: "100vh", backgroundColor: "green" }} >
                {isSmallScreen &&
                    <div style={{ height: "100%" }}>
                        <div style={{ minHeight: 80 }}></div>
                        <div style={{ verticalAlign: "middle", display: "flex" }}>
                            <img src={DSCodings} alt="logo" loading='lazy' style={{ margin: "auto", width: "300px", height: "300px" }} />

                        </div>
                        <div style={{ verticalAlign: "middle", display: "flex" }}>
                            <div style={{ margin: "auto" }}>
                                <Cursor />
                            </div>
                        </div>
                    </div>
                }
                {isLargeScreen &&
                    <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                        <div style={{ width: "40%", verticalAlign: "middle", display: "flex" }}>
                            <img src={DSCodings} alt="logo" loading='lazy' style={{ margin: "auto", width: "300px", height: "300px" }} />
                        </div>
                        <div style={{ width: "60%", verticalAlign: "middle", display: "flex" }}>
                            <div style={{ margin: "auto" }}>
                                <Cursor />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}

export default About

