import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import githublogo from "../images/githublogo.png"
import { useState, useEffect, RefObject } from "react";

type Props = { imageURL: string, loadingimageURL: string, textonCard: string, name: string, Ongithub: boolean, Githuburl: string, SoonOnGithub: boolean }

const useOnLoadImages = (ref: RefObject<HTMLElement>) => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const updateStatus = (images: HTMLImageElement[]) => {
      setStatus(
        images.map((image) => image.complete).every((item) => item === true)
      );
    };

    if (!ref?.current) return;

    const imagesLoaded = Array.from(ref.current.querySelectorAll("img"));

    if (imagesLoaded.length === 0) {
      setStatus(true);
      return;
    }

    imagesLoaded.forEach((image) => {
      image.addEventListener("load", () => updateStatus(imagesLoaded), {
        once: true
      });
      image.addEventListener("error", () => updateStatus(imagesLoaded), {
        once: true
      });
    });

    return;
  }, [ref]);

  return status;
};
function Cards({ imageURL, loadingimageURL, textonCard, name, Ongithub, Githuburl, SoonOnGithub }: Props) {
    const imgEl = React.useRef<HTMLImageElement>(null);
    // const [loaded, setLoaded] = React.useState(false);
    // const onImageLoaded = () => {setLoaded(true)
    // console.log("loaded", loaded)};
    // useEffect(() => {
    //     const imgElCurrent = imgEl.current;

    //     if (imgElCurrent) {
    //         imgElCurrent.addEventListener('load', onImageLoaded);
    //         return () => imgElCurrent.removeEventListener('load', onImageLoaded);
    //     }
    // }, [imgEl]);
    const loaded = useOnLoadImages(imgEl);

    const Ongithubcard = () => {
        if (Ongithub) {
            return (
                <CardActions>
                    <Button href={Githuburl} target="_blank" size="small"><img style={{ width: "25px", height: "25px" }} src={githublogo} /> &nbsp;&nbsp; View On Github</Button>
                </CardActions>
            )
        } else if (SoonOnGithub) {
            return (
                <CardActions>
                    <Button size="small"><img style={{ width: "25px", height: "25px" }} src={githublogo} /> &nbsp;&nbsp; Comming soon on github</Button>
                </CardActions>
            )
        }
        else return null
    }

    return (
        <div style={{ margin: 20 }}>
            <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                    {/* <div style={{ backgroundImage: loadingimageURL, backgroundSize: "cover" }}> */}
                    <CardMedia
                        component="img"
                        image={loadingimageURL}
                        alt="..."
                        style={!loaded ? { display: 'block' } : { display: 'none' }}
                    />
                    <CardMedia
                        ref={imgEl}
                        component="img"
                        image={imageURL}
                        alt="..."
                        style={loaded ? { display: 'inline-block' } : { display: 'none' }}
                    />
                    {/* </div> */}

                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {textonCard}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <Ongithubcard />
            </Card>
        </div>

    )
}

export default Cards