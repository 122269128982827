import medicijnenkluisje from "../images/medicijnenkluisje.jpeg"
import medicijnenkluisjesmal from "../images/medicijnenkluisje-small.jpeg"

import esp32 from "../images/esp32.png"
import esp32small from "../images/esp32-small.jpeg"

import GIP from "../images/GIP.png"
import GIPsmall from "../images/gip-small.jpeg"




export const Dataofprojects = [
    {
        name: "Medicin-safe",
        textonCard: "In my fourth year of middle school, my classmates and I created a project for a revalidation center aimed at helping people track their medication schedule. We designed it using an Arduino, which allowed us to develop a system that could accurately determine when medication needed to be taken.",
        imageURL: medicijnenkluisje,
        LoadingimageURL: medicijnenkluisjesmal,
        Ongithub: true,
        Githuburl: "https://github.com/DSCodings/Medicin-safe",
        SoonOnGithub: false,
    },
    {
        name: "fish-counter",
        textonCard: "During my fifth year of middle school, a group of four of us collaborated on a project focused on fish counting. We developed a system using an ESP32 microcontroller to record and transmit data to a Raspberry Pi for processing. The choice of ESP32 was deliberate due to its low power consumption, enabling efficient operation of the counting mechanism.",
        imageURL: esp32,
        LoadingimageURL: esp32small,
        Ongithub: false,
        Githuburl: "",
        SoonOnGithub: false,
    },
    {
        name: "motion analysis",
        textonCard: "During my final year of middle school, as part of our thesis project, two of my classmates and I developed a system that utilized two cameras to record and analyze walking patterns. The system generated front and side views of the walking motion and saved the average of each step in graphs that could be viewed in a PDF format. Additionally, users had the ability to watch the recorded videos or access the PDF for further analysis.",
        imageURL: GIP,
        LoadingimageURL: GIPsmall,
        Ongithub: false,
        Githuburl: "",
        SoonOnGithub: true,
    },
   

];