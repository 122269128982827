import React from 'react'
import '../App.css'
import '../css/Styles.css'
import Cards from './Cards'
import { Dataofprojects } from './JsonListOfProjects'
import Stack from '@mui/material/Stack';

type Props = { refs: any, cbref: any }

function Projects({ refs, cbref }: Props) {
  return (
    <div ref={refs} style={{ width: "100vw", minHeight: "100vh" }} >
      <div ref={cbref} style={{ width: "100vw", minHeight: "100vh" }} >
        <div style={{ margin: 40 }}>

          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            spacing={6}
            flexWrap="wrap"
          >
            {Dataofprojects.map((data) =>
              <Cards key={data.name} name={data.name} textonCard={data.textonCard} imageURL={data.imageURL} loadingimageURL={data.LoadingimageURL} Ongithub={data.Ongithub} Githuburl={data.Githuburl} SoonOnGithub={data.SoonOnGithub} />
            )}
          </Stack>
        </div>
      </div>
    </div>

  )
}

export default Projects