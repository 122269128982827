import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Router from "./Router/Router";
import UnexpectedError from "./Router/Errors/UnexpectedError"
import { AnimatePresence } from "framer-motion";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Router/>,
//     errorElement: <UnexpectedError /> 
//   },
// ]);
function App() {
  return (

      <Router />

  );
}

export default App;
