import { motion } from 'framer-motion'
import React, { Ref, useRef } from 'react'
import { useResolvedPath } from 'react-router'
import { getModeForUsageLocation } from 'typescript'

type Props = {
  page: string
  pagechager: Function
}

function Navbar({ page, pagechager }: Props) {


  function changenavbar(pagstring: string) {
    pagechager(pagstring)
  }

  function HomeBtn() {
    if (page == "Home") {
      return (<button className='buttonsnavselected' onClick={() => changenavbar("Home")} style={{ display: "inline-block", border: "2px", margin: 5, }}>Home</button>)
    } else {
      return (<button className='buttonsnav' onClick={() => changenavbar("Home")} style={{ display: "inline-block", border: "2px", margin: 5, }}>Home</button>)
    }
  }
  function ProjectsBTN() {
    if (page == "Projects") {
      return (<button className='buttonsnavselected' onClick={() => changenavbar("Projects")} style={{ display: "inline-block", border: "2px", margin: 5, }}>projects</button>)
    } else {
      return (<button className='buttonsnav' onClick={() => changenavbar("Projects")} style={{ display: "inline-block", border: "2px", margin: 5, }}>projects</button>)
    }
  }

  return (
    <motion.div  initial={{ y: -500 }}
    animate={{ y: 0 }}
      transition={{ duration: 0.1, ease: "easeOut" }} style={{ top: 0, position: "fixed", width: "98%", backgroundColor: 'black', borderRadius: 10, margin: 10, padding: 2, display: "flex", zIndex: 99, minHeight: "0px" }}>

      <h6 style={{ left: 5, display: "inline-block", border: "2px", color: "green", margin: 5, marginRight: 0, minHeight: "35px", fontSize: 27 }}>DSC</h6>
      <h6 style={{ left: 5, display: "inline-block", border: "2px", margin: 5, marginLeft: 0, fontSize: 27 }}>odings</h6>



      <div style={{ display: "inline-block", verticalAlign: "baseline", float: "right", right: 0, position: "absolute", height: "100%" }}>

        <HomeBtn />
        <ProjectsBTN />
 
      </div>

    </motion.div>
  )
}

export default Navbar