import React from 'react'
import githublogo from "../images/githublogo.png"
import youtube from "../images/youtube.png"
import { useMediaQuery } from 'react-responsive';


type Props = {}

function Extrainformateion({ }: Props) {
    const isSmallScreen = useMediaQuery({ maxWidth: 1200 });
    const isLargeScreen = useMediaQuery({ minWidth: 1201 });
    return (
        <div style={{ backgroundColor: "black", width: "100vw", minHeight: 500 }}>
            {isSmallScreen &&
                <>
                    <div style={{ height: "100%" }}>
                        <div style={{ verticalAlign: "middle", display: "flex", margin: "auto", marginTop: 50, height: "100%" }}>
                            <div style={{ verticalAlign: "top", display: "inline-block", textAlign: "center", margin: "auto", height: "100%" }}>
                                <a target="_blank" href="https://github.com/DSCodings"><img style={{ filter: "invert(1)", width: "50%", margin: "auto" }} src={githublogo} /> </a><h6 ></h6>
                                <a target="_blank" style={{ color: "white", bottom: 0, verticalAlign: "bottom" }} href="https://github.com/DSCodings">github: https://github.com/DSCodings</a>
                            </div>
                        </div>
                        <div style={{ verticalAlign: "middle", display: "flex", margin: "auto", marginTop: 50, height: "100%" }}>
                            <div style={{ verticalAlign: "top", display: "inline-block", textAlign: "center", margin: "auto", height: "100%" }}>
                                <a target="_blank" href="https://www.youtube.com/channel/UCdkfQongCgjQTtPgWoxbBog">
                                    <img style={{ width: "130%", margin: "auto", maxWidth: "80vw" }} src={youtube} />
                                </a><h6 ></h6>
                                <a target="_blank" style={{ color: "white", bottom: 0, verticalAlign: "bottom", fontSize: '65%' }} href="https://www.youtube.com/channel/UCdkfQongCgjQTtPgWoxbBog">YouTube: https://www.youtube.com/channel/UCdkfQongCgjQTtPgWoxbBog</a>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "100%" }}>
                        <div style={{ display: "flex", margin: "auto", marginTop: 50, height: "100%", flexDirection: "column", position: "relative", }}>
                            <div style={{ margin: "auto", }}> </div>
                            <div style={{}} ><a style={{ fontSize: "50% " }}> Contact me</a><div></div>
                                <a style={{ fontSize: "50% " }}>daan.swinters@gmail.com</a><div></div>
                                <a>
                                    <a style={{ color: "white", fontSize: "50% " }} href="mailto: daan.swinters@gmail.com">Send a mail</a>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div style={{ minHeight: 20 }}></div>
                    <a style={{ fontSize: "70%", margin: 20, }}><span>&#169;</span> Copyright 2023 Daan Swinters All Rights Reserved</a>
                </>
            }
            {isLargeScreen && <>
                <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                    <div style={{ width: "50%", verticalAlign: "middle", display: "flex", margin: "auto", marginTop: 50, height: "100%" }}>
                        <div style={{ verticalAlign: "top", display: "inline-block", textAlign: "center", margin: "auto", height: "100%" }}>
                            <a target="_blank" href="https://github.com/DSCodings"><img style={{ filter: "invert(1)", width: "50%", margin: "auto" }} src={githublogo} /> </a><h6 ></h6>
                        </div>
                    </div>
                    <div style={{ width: "50%", verticalAlign: "middle", display: "flex", margin: "auto", marginTop: 50, height: "100%" }}>
                        <div style={{ verticalAlign: "top", display: "inline-block", textAlign: "center", margin: "auto", height: "100%" }}>
                            <a target="_blank" href="https://www.youtube.com/channel/UCdkfQongCgjQTtPgWoxbBog"><img style={{ width: "130%", margin: "auto", }} src={youtube} /> </a><h6 ></h6>
                        </div>
                    </div>

                </div>
                <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                    <div style={{ width: "50%", verticalAlign: "middle", display: "flex", margin: "auto", marginTop: 50, height: "100%" }}>
                        <div style={{ verticalAlign: "top", display: "inline-block", textAlign: "center", margin: "auto", height: "100%" }}>
                            <a target="_blank" style={{ color: "white", bottom: 0, verticalAlign: "bottom" }} href="https://github.com/DSCodings">github: https://github.com/DSCodings</a>
                        </div>
                    </div>
                    <div style={{ width: "50%", verticalAlign: "middle", display: "flex", margin: "auto", marginTop: 50, height: "100%" }}>
                        <div style={{ verticalAlign: "top", display: "inline-block", textAlign: "center", margin: "auto", height: "100%" }}>
                            <a target="_blank" style={{ color: "white", bottom: 0, verticalAlign: "bottom", fontSize: '72%' }} href="https://www.youtube.com/channel/UCdkfQongCgjQTtPgWoxbBog">YouTube: https://www.youtube.com/channel/UCdkfQongCgjQTtPgWoxbBog</a>
                        </div>
                    </div>
                </div>
                <div style={{}} ><a style={{ fontSize: "50% " }}> Contact me</a><div></div>
                    <a style={{ fontSize: "50% " }}>daan.swinters@gmail.com</a><div></div>
                    <a><a style={{ color: "white", fontSize: "50% " }} href="mailto: daan.swinters@gmail.com">Send a mail</a></a>
                </div>
                <a style={{ fontSize: "80%", margin: 20, }}><span>&#169;</span> Copyright 2023 Daan Swinters All Rights Reserved</a>
            </>}
        </div>
    )
}

export default Extrainformateion