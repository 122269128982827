import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import '../App.css'
import '../css/Styles.css'

import Navbar from '../navbar/Navbar'
import About from './About'
import Projects from './Projects'
import Extrainformateion from './Extrainformateion'



function Home() {
    const Homepage = useRef(null)
    const Projectspage = useRef(null)
    const refHOME = useRef<HTMLDivElement>(null)
    const [pages, Setpages] = useState<string | any>('Home')
    function changenavbar(pagstring: string) {
        Setpages(pagstring)
        console.log(pagstring)
        if (pagstring === "Home") {
            scrollToSection(Homepage)
        }
        else if (pagstring === "Projects") {
            scrollToSection(Projectspage)
        }
    }
    const scrollToSection = (sectionref: any) => {
        window.scrollTo({
            top: sectionref.current.offsetTop,
            behavior: 'smooth'
        })
    }
    const useIntersectionObserver = (options: any, cb: any) => {
        const observer: any = React.useRef(null)
        return React.useCallback((node: any) => {
            if (!node) {
                if (observer.current) {
                    observer.current.disconnect()
                }
                return
            }
            observer.current = new window.IntersectionObserver(cb, options)
            observer.current.observe(node)
        }, [])
    }
    const cbRef = useIntersectionObserver({ threshold: 0.5 }, (entries: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting === true) {
                Setpages("Home")
            }
        })
    })
    const cbRef1 = useIntersectionObserver({ threshold: 0.5 }, (entries: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting === true) {
                Setpages("Projects")
            }

        })
    })
    return (
        <div className="App">
            <header className="App-header">
                {(() => {
                    switch (pages) {
                        case 'Home':
                            return <Navbar page="Home" pagechager={changenavbar} />
                        case 'Projects':
                            return <Navbar page="Projects" pagechager={changenavbar} />
                        default:
                            return <Navbar page="Home" pagechager={changenavbar} />
                    }
                })()}
                <About refs={Homepage} cbref={cbRef} />
                <Projects refs={Projectspage} cbref={cbRef1} />
                <Extrainformateion />
            </header>
        </div>
    )
}

export default Home