import React from 'react'
import { useRouteError } from "react-router-dom";

type Props = {}

function UnexpectedError({}: Props) {
    let error: any = useRouteError();
    console.error(error);
  
    return (
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    );
}

export default UnexpectedError