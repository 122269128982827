import React from 'react'

import { TypeAnimation } from 'react-type-animation';
type Props = {}

function Cursor({ }: Props) {
  return (
    <div>
      <div>
        <TypeAnimation
          sequence={[
            "Hi, I'm DSCodings!",
            3000,
            "Hi, I'm Daan Swinters!",
            3000,
          ]}
          wrapper="span"
          speed={50}
          style={{ fontSize: '4em', display: 'inline-block' }}
          repeat={Infinity}
        />
      </div>
      <div>
        <TypeAnimation
          sequence={[
            "I'm a developer in my free time.",
            2000,
            "I'm a developer who does it for fun!",
            2000,
          ]}
          wrapper="span"
          speed={50}
          style={{ fontSize: '2em', display: 'inline-block' }}
          repeat={Infinity}
        />
      </div>
    </div>
  )
}

export default Cursor